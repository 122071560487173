import { type CSSProperties, useCallback, useState } from 'react';
import clsx from 'clsx';

import type { Feature } from '@data/defaultFeatures.ts';
import Dots from '@assets/dots.svg';

import { FeatureContents } from '../FeatureContents';
import { InView } from 'react-intersection-observer';

const FeaturesContainer = ({ features }: { features: Feature[] }) => {
    const [visibilityArr, setVisibilityArr] = useState(new Array(features.length).fill(false));
    const [isVisible, setIsVisible] = useState(false);

    const onVisibilityChange = useCallback((inView: boolean) => {
        setIsVisible(inView);
    }, []);

    return (
        <InView
            skip={isVisible}
            as="div"
            onChange={onVisibilityChange}
            // rootMargin="500px"
            className="features-section__features-container">
            {features
                ? features.map((feature, i) => (
                      <div
                          key={feature.id}
                          className={clsx(
                              'features-section__feature',
                              `features-section__feature--${i + 1}`,
                          )}
                          style={
                              {
                                  zIndex: features.length - i,
                                  '--item-number': i + 1,
                                  ...(i % 2 ? { flexDirection: 'row-reverse' } : {}),
                                  ...(i + 1 === features.length
                                      ? { marginTop: 'var(--topbar-height)', marginBottom: 0 }
                                      : {}),
                              } as CSSProperties
                          }>
                          <FeatureContents
                              {...feature}
                              visibilityArr={visibilityArr}
                              setVisibilityArr={setVisibilityArr}
                              index={i}
                              isReversed={!!(i % 2)}
                              isContainerVisible={isVisible}
                          />

                          <div
                              className={clsx(
                                  'features-section__feature__dots-container',
                                  `features-section__feature__dots-container--${
                                      i % 2 === 0 ? 'left' : 'right'
                                  }`,
                              )}>
                              <img src={Dots.src} alt="dots" />
                          </div>
                      </div>
                  ))
                : null}
        </InView>
    );
};

export default FeaturesContainer;
