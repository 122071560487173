import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slickStyles.scss';

import React, {
    type Dispatch,
    Fragment,
    memo,
    type SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Slider, type SliderRef } from '@components/common/Slider';
import clsx from 'clsx';
import PlayIcon from '@assets/play-circle-outline.svg?react';
import { OpenContactModalButton } from '@components/OpenContactModalButton';
import styles from './FeatureContents.module.scss';
import type Player from 'video.js/dist/types/player';
import { VideoJS } from '@components/common/VideoJS';
import type { SourceObject } from 'video.js/dist/types/tech/tech';
import ArrowRight from '@assets/arrow-right.svg?react';
import { InView } from 'react-intersection-observer';
import { useMediaQuery } from '@hooks/useMediaQuery.tsx';

type FeatureList = {
    id: number;
    name: string;
    src: string;
    sources: SourceObject[];
    isVideo: boolean;
    srcset: string;
};

const FeatureContents = ({
    title,
    description,
    convertTitle,
    featuresList,
    isReversed,
    index,
    posterVideo,
    visibilityArr,
    setVisibilityArr,
    isContainerVisible,
}: {
    title: string;
    description: string;
    convertTitle: string;
    posterVideo: SourceObject[];
    featuresList: FeatureList[];
    isReversed: boolean;
    index: number;
    visibilityArr: boolean[];
    setVisibilityArr: Dispatch<SetStateAction<boolean[]>>;
    isContainerVisible: boolean;
}) => {
    const carouselRef = useRef<SliderRef>(null);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [displayVideo, setDisplayVideo] = useState(false);

    const [videoProgress, setVideoProgress] = useState(0);

    const isMobile = useMediaQuery('(max-width: 1075px)');

    const onClickItem = useCallback(
        (index: number) => {
            setVideoProgress(0);
            setCurrentIndex(index);
            carouselRef.current?.slickGoTo(index);

            document.dispatchEvent(new CustomEvent('video-player', { detail: { index } }));

            setDisplayVideo(true);
        },
        [setCurrentIndex],
    );

    const onTimeUpdate = useCallback(
        (progress: number) => {
            setVideoProgress(Math.floor(progress * 100));
        },
        [setVideoProgress],
    );

    const onSwipe = useCallback((direction: 'left' | 'right') => {
        setCurrentIndex(prev => {
            // because onSwipe is actually returning reversed values
            const nextIndex = prev + (direction === 'right' ? -1 : 1);

            if (direction === 'left') return nextIndex + 1 > featuresList.length ? 0 : nextIndex;

            return nextIndex < 0 ? featuresList.length - 1 : nextIndex;
        });
    }, []);

    const onGoNext = useCallback(() => {
        setVideoProgress(0);
        setCurrentIndex(prev => prev + 1);
        carouselRef.current?.slickGoTo(currentIndex + 1);
    }, [currentIndex]);

    const onVisibilityChange = useCallback(
        (inView: boolean) => {
            setVisibilityArr(prev => {
                const newArr = [...prev];

                newArr[index] = inView;

                return newArr;
            });
        },
        [index],
    );

    const onClickContainer = useCallback(() => {
        setVisibilityArr(prev => {
            const newArr = [...prev];

            newArr[index] = true;

            if (index > 0) {
                newArr[index - 1] = false;
            }
            return newArr;
        });
    }, [index]);

    const isPreviousSectionActive =
        visibilityArr[index - 1] && (index <= 1 ? true : !visibilityArr[index - 2]);

    const isSectionActive =
        visibilityArr[index] && (index === 0 ? true : !visibilityArr[index - 1]);

    const onClickOverlay = useCallback(() => {
        setCurrentIndex(0);
        setDisplayVideo(true);
    }, []);

    return (
        <InView
            className={
                styles.featureInnerContainer +
                ' ' +
                (isReversed ? styles.featureInnerContainerRevered : '')
            }
            as="div"
            onChange={onVisibilityChange}
            rootMargin="-200px">
            {!isMobile && (
                <div className={styles.featureCarouselContainer} onClick={onClickContainer}>
                    <div className={styles.featuresSectionCarouselContainer}>
                        <Slider
                            ref={carouselRef}
                            className={
                                styles.featuresSectionCarousel +
                                ' features-section-carousel ' +
                                (!displayVideo ? styles.featuresSectionCarouselHidden : '')
                            }
                            dots={false}
                            arrows={false}
                            onSwipe={onSwipe}
                            speed={200}
                            infinite>
                            {displayVideo &&
                                featuresList.map((item, i) => (
                                    <FeatureCarouselItem
                                        key={item.id}
                                        isActive={currentIndex === i && isSectionActive}
                                        index={i}
                                        {...item}
                                        onTimeUpdate={onTimeUpdate}
                                        onGoNext={
                                            currentIndex === i && i < featuresList.length - 1
                                                ? onGoNext
                                                : undefined
                                        }
                                    />
                                ))}
                        </Slider>
                        {!displayVideo && (
                            <FeatureCoverVideo
                                sources={posterVideo}
                                preload={
                                    index === 0
                                        ? true
                                        : isContainerVisible && isPreviousSectionActive
                                }
                                isActive={isSectionActive}
                                onClickOverlay={onClickOverlay}
                            />
                        )}
                    </div>
                </div>
            )}
            <InView as="div" onChange={onVisibilityChange} className={styles.featureContents}>
                <h3 className={styles.featureHeading}>{title}</h3>
                <span className={styles.featureDescription}>{description}</span>

                <div className={styles.featureList}>
                    {featuresList.map((item, i) => (
                        <Fragment key={item.id}>
                            <FeatureListItem
                                name={item.name}
                                index={i}
                                isActive={currentIndex === i}
                                onClickItem={onClickItem}
                                progress={currentIndex === i ? videoProgress : 0}
                            />
                        </Fragment>
                    ))}

                    {isMobile && (
                        <div className={styles.featureContentMobileVideoContainer}>
                            {displayVideo ? (
                                <FeatureCarouselItem
                                    key={featuresList[currentIndex].id}
                                    isActive
                                    index={currentIndex}
                                    {...featuresList[currentIndex]}
                                    onTimeUpdate={onTimeUpdate}
                                    onGoNext={onGoNext}
                                />
                            ) : (
                                <FeatureCoverVideo
                                    sources={posterVideo}
                                    preload={isContainerVisible && isPreviousSectionActive}
                                    isActive={isSectionActive && isPreviousSectionActive}
                                    onClickOverlay={onClickOverlay}
                                />
                            )}
                        </div>
                    )}
                </div>

                <OpenContactModalButton
                    className={styles.featureContentButton}
                    title={convertTitle}
                    convertActionTitle={convertTitle}
                    description={'Du erhältst einen Link zu deiner kostenlosen Demoversion.'}
                    withLogo>
                    {convertTitle}
                    <span className={styles.featureContentButtonIcon}>
                        <ArrowRight />
                    </span>
                </OpenContactModalButton>
            </InView>
        </InView>
    );
};

const FeatureCoverVideo = memo(
    ({
        sources,
        isActive,
        preload,
        onClickOverlay,
    }: {
        sources: SourceObject[];
        preload: boolean;
        isActive: boolean;
        onClickOverlay: () => void;
    }) => {
        const ref = useRef<any>(null);
        const playRef = useRef<Player>(null);

        const onTimeUpdate = useCallback(() => {
            if (!playRef.current) return;

            if (!isActive) {
                playRef.current.pause();
                return;
            }

            // onTimeUpdateProp(
            //     (playRef.current.currentTime() || 0) / (playRef.current.duration() || 1),
            // );
        }, [isActive]);

        // not using this preload because it causes flicker
        const options = useMemo(
            () => ({
                autoplay: preload,
                preload: 'none',
                fill: true,
                controls: false,
                sources,
            }),
            [sources],
        );

        useEffect(() => {
            if (!playRef.current) return;

            if (!isActive) {
                ref.current.setIsAutoPlaying(true);
                playRef.current.muted(true);
                playRef.current.pause();
            } else {
                ref.current.setIsAutoPlaying(true);
                playRef.current.muted(true);
                playRef.current.play();
            }
        }, [isActive]);

        // logic for manual preloading
        useEffect(() => {
            let timeout: NodeJS.Timeout;

            if (!playRef.current || !preload) return;
            ref.current.setIsAutoPlaying(true);
            playRef.current.muted(true);
            playRef.current.play();

            timeout = setTimeout(() => {
                playRef.current?.pause();
            }, 200);

            return () => {
                clearTimeout(timeout);
            };
        }, [preload]);

        return (
            <div className={styles.featureCarouselItemCoverContainer}>
                <div className={styles.featureCarouselItemCover}>
                    <VideoJS
                        ref={ref}
                        playerRef={playRef}
                        onTimeUpdate={onTimeUpdate}
                        options={options}
                    />

                    <div
                        className={styles.featureCarouselItemCoverOverlay}
                        onClick={onClickOverlay}>
                        <button className={styles.featureCarouselItemCoverPlayButton}>
                            <span
                                className={
                                    styles.featureCarouselItemCoverPlayButtonPlaceholder
                                }></span>
                        </button>
                    </div>
                </div>
            </div>
        );
    },
);

const FeatureListItem = memo(
    ({
        index,
        name,
        progress,
        onClickItem,
        isActive,
    }: {
        index: number;
        onClickItem: (index: number) => void;
        name: string;
        progress: number;
        isActive: boolean;
    }) => {
        const onClick = useCallback(() => onClickItem(index), [index]);

        const progressBarStyle = useMemo(() => ({ width: `${progress}%` }), [progress]);

        return (
            <div
                className={
                    styles.featureListItem + (isActive ? ' ' + styles.featureListItemActive : '')
                }
                onClick={onClick}>
                <PlayIcon />
                {name}
                {isActive && (
                    <div
                        className={
                            styles.featureListItemProgress +
                            (isActive ? ' ' + styles.featureListItemProgressActive : '')
                        }
                        style={progressBarStyle}></div>
                )}
            </div>
        );
    },
);

const FeatureCarouselItem = memo(
    ({
        isVideo,
        src,
        sources,
        isActive,
        index,
        onTimeUpdate: onTimeUpdateProp,
        onGoNext,
    }: FeatureList & {
        onTimeUpdate: (progress: number) => void;
        isActive: boolean;
        onGoNext: (() => void) | undefined;
        index: number;
    }) => {
        const ref = useRef<HTMLDivElement>(null);
        const videoContainerRef = useRef<any>(null);
        const playerRef = useRef<Player>(null);
        const timeoutRef = useRef<NodeJS.Timeout>();
        const isFirstActive = useRef(isActive);

        const onTimeUpdate = useCallback(() => {
            if (!playerRef.current) return;

            if (!isActive) {
                playerRef.current.pause();
                return;
            }

            onTimeUpdateProp(
                (playerRef.current.currentTime() || 0) / (playerRef.current.duration() || 1),
            );
        }, [isActive]);

        const options = useMemo(
            () => ({
                autoplay: false,
                fill: true,
                controls: true,
                preload: 'none',
                sources,
            }),
            [sources],
        );

        useEffect(() => {
            if (!playerRef.current || !isActive || !isFirstActive.current) {
                isFirstActive.current = true;
                clearTimeout(timeoutRef.current);
                return;
            }

            timeoutRef.current = setTimeout(() => {
                if (!ref.current?.closest('.slick-active')) return;

                if (!playerRef.current?.paused()) {
                    clearTimeout(timeoutRef.current);
                    return;
                }

                if (!isFirstActive.current) return;

                // if the video has played before don't resume
                if (playerRef.current?.currentTime() > 0) {
                    return;
                }

                isFirstActive.current = false;
                playerRef.current?.muted();
                playerRef.current?.play();
            }, 500);

            return () => {
                clearTimeout(timeoutRef.current);
            };
        }, [isActive]);

        useEffect(() => {
            if (!playerRef.current) return;

            if (!isActive) {
                playerRef.current.pause();
            }
        }, [isActive]);

        const videoPlayerCallback = useCallback((e: any) => {
            setTimeout(() => {
                if (!playerRef.current) return;

                if (e.detail.index !== index) return;

                if (!ref.current?.closest('.slick-active')) return;

                playerRef.current.play();
            }, 500);
        }, []);

        useEffect(() => {
            document.addEventListener('video-player', videoPlayerCallback);

            return () => document.removeEventListener('video-player', videoPlayerCallback);
        }, []);

        if (isVideo) {
            return (
                <div ref={ref} className={styles.featuresSectionCarouselItem}>
                    <VideoJS
                        ref={videoContainerRef}
                        playerRef={playerRef}
                        onTimeUpdate={onTimeUpdate}
                        onGoNext={onGoNext}
                        options={options}
                    />
                </div>
            );
        }

        return null;
    },
);

export default memo(FeatureContents);
